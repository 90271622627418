import React, { useState, useEffect } from 'react'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd'
import {
  faHourglassEnd,
  faSpinner,
  faCircleCheck,
  faUserCheck,
  faPaperclip,
  faUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery, gql, useMutation } from '@apollo/client'
import { Order, Orders } from 'src/types'
import { useNavigate } from 'react-router-dom'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface KanbanBoardProps {
  tasks: Orders
}

interface Task {
  id: string
  paperType: string
  instructions: string
  pages: number
  dueDate: string
  totalCost: number
  depositAmount: number
  status: string
  filesUploaded: boolean
}

interface Tasks {
  pending: { icon: typeof faHourglassEnd; tasks: Task[] }
  inProgress: { icon: typeof faSpinner; tasks: Task[] }
  completed: { icon: typeof faCircleCheck; tasks: Task[] }
  reviewed: { icon: typeof faUserCheck; tasks: Task[] }
}

interface PaperOption {
  value: string
  label: string
}

const paperOptions: PaperOption[] = [
  { value: 'essay', label: 'Essay (any type)' },
  { value: 'admission_essay', label: 'Admission essay' },
  { value: 'annotated_bibliography', label: 'Annotated bibliography' },
  { value: 'argumentative_essay', label: 'Argumentative essay' },
  { value: 'article_review', label: 'Article review' },
  { value: 'book_movie_review', label: 'Book/movie review' },
  { value: 'business_plan', label: 'Business plan' },
  { value: 'presentation_speech', label: 'Presentation speech' },
  { value: 'research_proposal', label: 'Research proposal' },
  { value: 'case_study', label: 'Case study' },
  { value: 'critical_thinking', label: 'Critical thinking' },
  { value: 'course_work', label: 'Course work' },
  { value: 'term_paper', label: 'Term paper' },
  {
    value: 'thesis_dissertation_chapter',
    label: 'Thesis/Dissertation chapter',
  },
  { value: 'creative_writing', label: 'Creative writing' },
  { value: 'research_paper', label: 'Research Paper' },
  { value: 'other', label: 'Other' },
]

const getPaperTypeLabel = (paperType: string) => {
  const paper = paperOptions.find((option) => option.value === paperType)
  return paper ? paper.label : paperType
}

const UPDATE_ORDER_STATUS = gql`
  mutation UpdateOrderStatus($orderId: String!, $status: String!) {
    updateOrderStatus(orderId: $orderId, status: $status) {
      id
      status
    }
  }
`

const KanbanBoard: React.FC<KanbanBoardProps> = ({ tasks }) => {
  const [vissibleTasks, setVissibleTasks] = useState(tasks)
  const navigate = useNavigate()
  const [updateOrderStatus] = useMutation(UPDATE_ORDER_STATUS, {
    onError: (error) => {
      console.error('Failed to update order status', error)
    },
  })

  // Update `vissibleTasks` if `tasks` prop changes
  useEffect(() => {
    setVissibleTasks(tasks)
  }, [tasks])

  const onDragEnd = async (result: DropResult) => {
    const { source, destination } = result
    if (!destination) return // Dropped outside

    const sourceColumn = tasks[source.droppableId as keyof Tasks].tasks
    const destinationColumn =
      tasks[destination.droppableId as keyof Tasks].tasks
    const sourceItems = Array.from(sourceColumn)
    const destItems = Array.from(destinationColumn)

    const [movedItem] = sourceItems.splice(source.index, 1)
    destItems.splice(destination.index, 0, movedItem)

    // Update the status of the moved item
    const newStatus =
      destination.droppableId === 'inProgress'
        ? 'IN_PROGRESS'
        : destination.droppableId.toUpperCase()
    movedItem.status = newStatus

    setVissibleTasks({
      ...tasks,
      [source.droppableId]: {
        ...tasks[source.droppableId as keyof Tasks],
        tasks: sourceItems,
      },
      [destination.droppableId]: {
        ...tasks[destination.droppableId as keyof Tasks],
        tasks: destItems,
      },
    })

    try {
      await updateOrderStatus({
        variables: {
          orderId: movedItem.id,
          status: newStatus,
        },
        optimisticResponse: {
          updateOrderStatus: {
            id: movedItem.id,
            status: newStatus,
            __typename: 'Order',
          },
        },
      })
    } catch (error) {
      console.error('Failed to update order status', error)
    }

    // Optionally, update the backend to persist the task's new status
    // updateTaskStatusInBackend(movedItem.id, movedItem.status)
  }

  const getColumnStyles = (columnId: string) => {
    switch (columnId) {
      case 'pending':
        return { bgColor: 'border-error border-[1px]', iconColor: 'text-error' }
      case 'inProgress':
        return {
          bgColor: 'border-warning border-[1px]',
          iconColor: 'text-warning',
        }
      case 'completed':
        return { bgColor: 'border-info border-[1px]', iconColor: 'text-info' }
      case 'reviewed':
        return {
          bgColor: 'border-primary border-[1px]',
          iconColor: 'text-primary',
        }
      default:
        return { bgColor: 'bg-white', iconColor: 'text-black' }
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="grid grid-cols-4 gap-4 p-4 ">
        {Object.keys(tasks).map((columnId) => {
          const { bgColor, iconColor } = getColumnStyles(columnId)
          return (
            <Droppable droppableId={columnId} key={columnId}>
              {(provided) => (
                <div
                  className={`rounded-lg shadow-lg p-4 min-h-[500px] border-2 ${bgColor} bg-on-primary`}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <div
                    className={`flex justify-start mb-4 items-center gap-4 ${iconColor}`}
                  >
                    <FontAwesomeIcon
                      icon={tasks[columnId as keyof Tasks].icon as IconProp}
                    />
                    <h3 className="text-lg font-semibold text-center capitalize">
                      {columnId.replace(/([A-Z])/g, ' $1').toUpperCase()}
                    </h3>
                  </div>
                  {tasks[columnId as keyof Tasks].tasks.map(
                    (vissibleTasks: Order, index: number) => (
                      <Draggable
                        draggableId={vissibleTasks.id}
                        index={index}
                        key={vissibleTasks.id}
                      >
                        {(provided) => (
                          <div
                            className="bg-white p-4 my-2 rounded shadow-lg hover:bg-gray-50"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="font-semibold">
                              {getPaperTypeLabel(vissibleTasks.paperType)}
                            </div>
                            <div
                              className="text-sm text-gray-600 mb-2"
                              dangerouslySetInnerHTML={{
                                __html: vissibleTasks.instructions,
                              }}
                            />
                            <div className="text-xs">
                              <p>Pages: {vissibleTasks.numberOfPages}</p>
                              <p>Due: {vissibleTasks.dueDate}</p>
                              <p>Total: ${vissibleTasks.totalAmount}</p>
                              <p>Deposit: ${vissibleTasks.depositAmount}</p>
                              <p>Status: {vissibleTasks.status}</p>
                              {vissibleTasks.uploadedFiles && (
                                <div className="mt-2">
                                  <FontAwesomeIcon
                                    icon={faPaperclip as IconProp}
                                    size="sm"
                                  />{' '}
                                  Files uploaded
                                </div>
                              )}
                            </div>
                            <div
                              className="flex justify-end cursor-pointer"
                              onClick={() =>
                                navigate(`/orders/${vissibleTasks.id}`)
                              }
                            >
                              <FontAwesomeIcon
                                icon={faUpRightFromSquare as IconProp}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ),
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )
        })}
      </div>
    </DragDropContext>
  )
}

export default KanbanBoard
