import React, { useState, useEffect, useRef } from 'react'
import { useMutation, gql, ApolloError } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { addFile, clearFiles, removeFile } from '../../slices/uploadSlice'
import Select, { StylesConfig } from 'react-select'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Icon from '@mdi/react'
import { FaChevronUp, FaRegCircle } from 'react-icons/fa'
import { mdiPlus, mdiMinus } from '@mdi/js'
import { PhoneInput, CountryIso2 } from 'react-international-phone'
import { PhoneNumberUtil } from 'google-libphonenumber'
import axios from 'axios'
import { getCookie } from '../../utils/cookie'
import './CompleteRegistration.css'
import { motion } from 'framer-motion'
import { EmbedBlot } from 'parchment'
import FileUpload from '../../components/FileManager/FileUpload'
import FileList from '../../components/FileManager/FileList'
import FilePreview from 'src/components/FileManager/FilePreview'
import InputMask from 'react-input-mask'
import Quill from 'quill'
import '../../components/FileManager/Quill/FileBlot'
import { FileValue } from 'src/types'
import 'react-international-phone/style.css'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { toast } from 'react-toastify'
import { v4 as uuid } from 'uuid'

interface RedisData {
  email: string
  paperType: string
  numberOfPages: number
  dueDate: string
}

interface PaperOption {
  value: string
  label: string
}

interface ParsedCountry {
  name: string
  iso2: CountryIso2
  dialCode: string
  format: any
  priority: number | undefined
  areaCodes: string[] | undefined
}

interface FileData {
  id: string
  name: string
  size: string
  url: string
  mimeType: string
}

// GraphQL mutations
const CREATE_STUDENT_MUTATION = gql`
  mutation CreateStudent($input: CreateStudentInput!) {
    createStudent(input: $input) {
      id
      firstName
      lastName
      email
      dateOfBirth
      phoneNumber
      userName
      role
    }
  }
`

const CREATE_ORDER_MUTATION = gql`
  mutation CreateOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      success
      message
      order {
        studentId
        instructions
        paperType
        numberOfPages
        dueDate
        uploadedFiles {
          id
          name
          url
          size
          mimeType
        }
      }
    }
  }
`

const ATTACH_FILES = gql`
  mutation AttachFiles($input: AttachFilesInput!) {
    attachFiles(input: $input) {
      id
      documentPath
    }
  }
`

const BlockEmbed = Quill.import('blots/block/embed') as typeof EmbedBlot
class FileBlot extends BlockEmbed {
  static blotName = 'file'
  static tagName = 'a'

  static create(value: FileValue) {
    const node = super.create() as HTMLAnchorElement
    node.setAttribute('download', value.name)
    node.textContent = value.name
    return node
  }

  static value(node: HTMLElement) {
    return {
      url: (node as HTMLAnchorElement).getAttribute('href') || '',
      name: node.textContent || '',
    }
  }
}
Quill.register(FileBlot)

const phoneUtil = PhoneNumberUtil.getInstance()

const CompleteRegistration: React.FC = () => {
  const [redisData, setRedisData] = useState<RedisData | null>(null)
  const [selectedPaperType, setSelectedPaperType] =
    useState<PaperOption | null>(null)
  const [numberOfPages, setNumberOfPages] = useState<number>(1)
  const [dueDate, setDueDate] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [dateOfBirth, setDateOfBirth] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true)
  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')
  const [token, setToken] = useState<string | null>(null)
  const [content, setContent] = useState<string>('')
  const [summary, setSummary] = useState<string>('')
  const [myDetails, setMyDetails] = useState<string[]>([])
  const [showSummary, setShowSummary] = useState(false)
  const [showInstructions, setShowInstructions] = useState(false)
  const [showMyDetails, setShowMyDetails] = useState(false)
  const [rotateSummary, setRotateSummary] = useState(false)
  const [rotateInstructions, setRotateInstructions] = useState(false)
  const [rotateMyDetails, setRotateMyDetails] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState<FileValue[]>([])
  const [fileError, setFileError] = useState<string>('')

  const editorRef = useRef<ReactQuill | null>(null)
  const dispatch = useDispatch()

  const paperOptions: PaperOption[] = [
    { value: 'ESSAY', label: 'Essay (any type)' },
    { value: 'ADMISSION_ESSAY', label: 'Admission essay' },
    { value: 'ANNOTATED_BIBLIOGRAPHY', label: 'Annotated bibliography' },
    { value: 'ARGUMENTATIVE_ESSAY', label: 'Argumentative essay' },
    { value: 'ARTICLE_REVIEW', label: 'Article review' },
    { value: 'BOOK_MOVIE_REVIEW', label: 'Book/movie review' },
    { value: 'BUSINESS_PLAN', label: 'Business plan' },
    { value: 'PRESENTATION_SPEECH', label: 'Presentation speech' },
    { value: 'RESEARCH_PROPOSAL', label: 'Research proposal' },
    { value: 'CASE_STUDY', label: 'Case study' },
    { value: 'CRITICAL_THINKING', label: 'Critical thinking' },
    { value: 'COURSE_WORK', label: 'Course work' },
    { value: 'TERM_PAPER', label: 'Term paper' },
    {
      value: 'THESIS_DISSERTATION_CHAPTER',
      label: 'Thesis/Dissertation chapter',
    },
    { value: 'CREATIVE_WRITING', label: 'Creative writing' },
    { value: 'OTHER', label: 'Other' },
  ]

  const baseUrl =
    process.env.REACT_APP_BACKEND_URL ||
    'https://anyday-backend-service-969666510139.us-central1.run.app'

  const customStyles: StylesConfig<PaperOption, false> = {
    control: (provided, state) => ({
      ...provided,
      background: 'white',
      display: 'flex',
      flexWrap: 'nowrap',
      borderColor: 'transparent',
      '&:hover': { borderColor: state.isFocused ? 'gray' : 'gray' },
      boxShadow: state.isFocused ? '0 0 0 0px var(--focus-color)' : 'none',
      border: '0.5px solid black',
      width: 'full',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: 'black',
      width: '0.5px',
      margin: '0 8px',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
      color: 'white',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? 'var(--selected-background)'
        : state.isFocused
          ? 'var(--focus-background)'
          : 'var(--input-background)',
      color: 'var(--text-color)',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'var(--text-color)',
    }),
  }

  const [createStudent, { loading: studentLoading, error: studentError }] =
    useMutation(CREATE_STUDENT_MUTATION)

  const [createOrder, { loading: orderLoading, error: orderError }] =
    useMutation(CREATE_ORDER_MUTATION)

  const [attachFiles, { loading: fileLoading, error: attachFileError }] =
    useMutation(ATTACH_FILES)

  useEffect(() => {
    let isMounted = true

    const fetchData = async () => {
      try {
        const tokenFromCookie = getCookie('token')
        if (!tokenFromCookie) {
          console.error('Token not found')
          return
        }

        setToken(tokenFromCookie)

        const url = `${baseUrl}/api/redis/user-data`
        const response = await axios.post<RedisData>(url, null, {
          headers: {
            Authorization: `Bearer ${tokenFromCookie}`,
            'Content-Type': 'application/json',
          },
        })

        const formattedDate = new Date(response.data.dueDate)
          .toISOString()
          .slice(0, 16)

        if (isMounted) {
          setRedisData(response.data)
          setEmail(response.data.email)
          setNumberOfPages(response.data.numberOfPages)
          setDueDate(formattedDate)
          setSelectedPaperType(
            paperOptions.find(
              (option) => option.value === response.data.paperType,
            ) || null,
          )
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()

    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    if (!editorRef.current) return
    const quill = editorRef.current.getEditor()

    const insertHeartEmoji = () => {
      const cursorPosition = quill.getSelection()?.index || 0
      quill.insertText(cursorPosition, '❤️', 'user')
      const newPosition = cursorPosition + 2
      quill.setSelection(newPosition, 0)
    }

    const heartButton = document.createElement('button')
    heartButton.innerHTML = '❤️'
    heartButton.className = 'ql-heart'
    heartButton.addEventListener('click', insertHeartEmoji)

    const fileInput = document.createElement('input')
    fileInput.setAttribute('type', 'file')
    fileInput.setAttribute('multiple', '')
    fileInput.style.display = 'none'
    fileInput.className = 'ql-file-input'
    fileInput.addEventListener('change', (event) => {
      const files = (event.target as HTMLInputElement).files
      if (files) {
        const fileArray = Array.from(files)
        fileArray.forEach((file) => {
          const fileData: FileValue = {
            id: uuid(),
            name: file.name,
            size: file.size,
            mimeType: file.type,
            file: file,
            url: URL.createObjectURL(file),
          }

          const cursorPosition = quill.getSelection()?.index || 0
          const fileLink = `<a href="${fileData.url}" download="${fileData.name}"> 📁 ${fileData.name}</a>`
          quill.clipboard.dangerouslyPasteHTML(cursorPosition, fileLink)

          dispatch(addFile(fileData))
          setUploadedFiles((prevFiles) => [...prevFiles, fileData])
        })
      }
    })

    const fileButton = document.createElement('button')
    fileButton.innerHTML = '📁'
    fileButton.className = 'ql-file'
    fileButton.addEventListener('click', () => fileInput.click())

    const toolbar = quill.getModule('toolbar')
    if (toolbar && quill.root.parentNode) {
      const toolbarContainer = quill.root.parentNode
        .previousSibling as HTMLElement

      if (!toolbarContainer.querySelector('.ql-heart')) {
        toolbarContainer.appendChild(heartButton)
      }

      if (!toolbarContainer.querySelector('.ql-file')) {
        toolbarContainer.appendChild(fileButton)
      }

      if (!toolbarContainer.querySelector('.ql-file-input')) {
        toolbarContainer.appendChild(fileInput)
      }

      const select = toolbarContainer.querySelector(
        '.ql-size select',
      ) as HTMLSelectElement
      if (select) {
        select.addEventListener('change', () => {
          const selectedValue = select.value
          if (editorRef.current) {
            const q = editorRef.current.getEditor()
            if (
              selectedValue === 'small' ||
              selectedValue === 'large' ||
              selectedValue === 'huge'
            ) {
              q.format('size', selectedValue)
            } else {
              q.format('size', false)
            }
          }
        })
      }
    }
  }, [dispatch])

  const generateSummary = (text: string): string => {
    const sentences = text.split('. ').slice(0, 3)
    return sentences.join('. ') + (sentences.length > 3 ? '...' : '')
  }

  const handleSummarize = () => {
    if (editorRef.current) {
      const quill = editorRef.current.getEditor()
      const editorText = quill.getText().trim()
      if (editorText) {
        const summaryText = generateSummary(editorText)
        setSummary(summaryText)
      }
    }
  }

  const toggleShowSummary = () => {
    handleSummarize()
    setShowSummary((prev) => !prev)
    setRotateSummary((prev) => !prev)
  }

  const toggleShowInstructions = () => {
    setShowInstructions((prev) => !prev)
    setRotateInstructions((prev) => !prev)
  }

  const toggleShowMyDetails = () => {
    setMyDetails((prevDetails) => {
      const upDatedDetails = [...prevDetails]
      if (firstName && !upDatedDetails.includes(firstName)) {
        upDatedDetails.push(firstName)
      }
      if (lastName && !upDatedDetails.includes(lastName)) {
        upDatedDetails.push(lastName)
      }
      if (email && !upDatedDetails.includes(email)) {
        upDatedDetails.push(email)
      }
      if (dateOfBirth && !upDatedDetails.includes(dateOfBirth)) {
        upDatedDetails.push(dateOfBirth)
      }
      if (phoneNumber && !upDatedDetails.includes(phoneNumber)) {
        upDatedDetails.push(phoneNumber)
      }
      return upDatedDetails
    })
    setShowMyDetails((prev) => !prev)
    setRotateMyDetails((prev) => !prev)
  }

  const validateContent = () => {
    const plainText = content.replace(/<[^>]*>/g, '').trim()
    return plainText.length > 0
  }

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const validatePhoneNumber = (phone: string): boolean => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
    } catch {
      return false
    }
  }

  const validatePassword = (password: string): string => {
    const minLength = 8
    const hasNumber = /\d/
    const hasSpecialChar = /[!@#$%^&*]/
    const hasLetter = /[a-zA-Z]/

    if (password.length < minLength)
      return 'Password must be at least 8 characters long'
    if (!hasNumber.test(password))
      return 'Password must contain at least one number'
    if (!hasSpecialChar.test(password))
      return 'Password must contain at least one special character'
    if (!hasLetter.test(password))
      return 'Password must contain at least one letter'
    return ''
  }

  const handleShowPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setShowPassword((prev) => !prev)
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value
    setEmail(newEmail)
    setIsEmailValid(validateEmail(newEmail))
  }

  const handlePhoneNumberChange = (
    phoneNumber: string,
    meta: { country: ParsedCountry; inputValue: string },
  ) => {
    setPhoneNumber(phoneNumber)
    if (phoneNumber.length > meta.country.dialCode.length + 1) {
      setIsPhoneNumberValid(validatePhoneNumber(phoneNumber))
    } else {
      setIsPhoneNumberValid(true)
    }
  }

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value
    setPassword(newPassword)
    setPasswordError(validatePassword(newPassword))
  }

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newConfirmPassword = e.target.value
    setConfirmPassword(newConfirmPassword)
    if (password !== newConfirmPassword) {
      setConfirmPasswordError('Passwords do not match')
    } else {
      setConfirmPasswordError('')
    }
  }

  const handleFileUpload = (files: File[]) => {
    setFileError('')
    const newFiles: FileValue[] = files.map((file) => ({
      id: uuid(),
      name: file.name,
      size: file.size,
      mimeType: file.type,
      file: file,
      url: URL.createObjectURL(file),
    }))
    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles])
    newFiles.forEach((f) => dispatch(addFile(f)))
  }

  const handleFileUploadError = (errorMessage: string) => {
    setFileError(errorMessage)
  }

  const handleRemoveFile = (id: string) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id))
    dispatch(removeFile(id))
  }

  const handleClearFiles = () => {
    setUploadedFiles([])
    dispatch(clearFiles())
  }

  const handlePageIncrement = () => {
    setNumberOfPages((prev) => prev + 1)
  }

  const handlePageDecrement = () => {
    if (numberOfPages > 1) {
      setNumberOfPages((prev) => prev - 1)
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (fileError) {
      toast.error('Please correct file errors before submitting.', {
        position: 'top-right',
      })
      return
    }

    if (!validateContent()) {
      alert('Order instructions cannot be empty!')
      return
    }

    try {
      // Create student
      const { data: studentData } = await createStudent({
        variables: {
          input: {
            firstName,
            lastName,
            email,
            dateOfBirth,
            phoneNumber,
            password,
          },
        },
      })

      const studentId = studentData.createStudent.id

      // Upload files
      let fileData: FileData[] = []
      if (uploadedFiles.length > 0) {
        const formData = new FormData()
        for (const fileValue of uploadedFiles) {
          if (fileValue.file) {
            formData.append('files', fileValue.file)
          } else {
            console.error('File object missing in FileValue:', fileValue)
          }
        }

        const url = `${baseUrl}/api/upload/files`
        const response = await axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        if (Array.isArray(response.data.uploadedFiles)) {
          fileData = response.data.uploadedFiles.map((file: FileData) => ({
            id: file.id,
            name: file.name,
            url: file.url,
            size: file.size.toString(),
            mimeType: file.mimeType,
          }))
        }
      }

      const depositAmount = numberOfPages * 20 * 0.5

      // Create order
      await createOrder({
        variables: {
          input: {
            studentId,
            instructions: content,
            paperType: selectedPaperType?.value.toUpperCase(),
            numberOfPages,
            dueDate,
            depositAmount,
            uploadedFiles: fileData,
          },
        },
      })

      toast.success(
        'Order created successfully. Login to your account to view your order.',
        {
          position: 'top-right',
        },
      )
      window.location.href = '/'
    } catch (error) {
      if (error instanceof ApolloError) {
        toast.error(`ApolloError: ${error.message}`, { position: 'top-right' })
      } else if (error instanceof Error) {
        toast.error(`Error: ${error.message}`, { position: 'top-right' })
      } else {
        toast.error('An unexpected error occurred.', { position: 'top-right' })
        console.error('Unexpected Error:', error)
      }
    }
  }

  return (
    <div className="registration-container bg-gradient-to-r to-primary from-secondary-container h-full">
      <div className="flex items-center justify-start px-[75px] pt-[35px]">
        <div className="">
          <a href="/">
            <img src="/media/logo.png" alt="Any Day Logo" className="logo" />
          </a>
        </div>
      </div>
      <div className="text-2xl mx-[75px] py-[15px] font-light">
        Complete Your Order
      </div>
      <div
        className="flex items-start justify-start gap-[5px]"
        id="text-editor"
      >
        <div className="ml-[35px] px-[35px]">
          <form onSubmit={handleSubmit}>
            <div className="bg-on-primary editor-container shadow-2xl">
              <ReactQuill
                ref={editorRef}
                theme="snow"
                modules={{
                  toolbar: {
                    container: [
                      [{ size: ['small', false, 'large', 'huge'] }],
                      [{ font: [] }],
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      [{ align: [] }],
                      ['bold', 'italic', 'underline', 'strike'],
                      [{ color: [] }, { background: [] }],
                      ['link', 'image', 'video', 'formula'],
                      ['clean'],
                    ],
                  },
                }}
                placeholder="Add instructions here or leave a ❤️"
                value={content}
                onChange={setContent}
              />
            </div>
            <div className="flex flex-row justify-between">
              {/* Left side form fields */}
              <div className="flex flex-col items-start justify-center gap-[15px] mt-[35px]">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="first-name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                  className="shadow border-0 focus:border-1 rounded w-[270px] py-2 px-3 text-secondary"
                />

                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="last-name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                  className="shadow border-0 focus:border-1 rounded w-[270px] py-2 px-3 text-secondary"
                />

                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  required
                  onChange={handleEmailChange}
                  className={`shadow border-0 focus:border-1 rounded w-[270px] py-2 px-3 text-secondary ${
                    !isEmailValid ? 'border-error' : ''
                  }`}
                />
                {!isEmailValid && (
                  <p className="text-error">
                    Please enter a valid email address.
                  </p>
                )}

                <label
                  htmlFor="date-of-birth"
                  className="block text-sm font-medium text-gray-700"
                >
                  Date of Birth
                </label>
                <input
                  type="date"
                  id="date-of-birth"
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                  required
                  className="shadow border-0 focus:border-1 rounded w-[270px] py-2 px-3 text-secondary"
                />

                <label
                  htmlFor="phone-number"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone Number
                </label>
                <PhoneInput
                  defaultCountry="us"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  className={`phone-input ${!isPhoneNumberValid ? 'border-error' : ''}`}
                />
                {!isPhoneNumberValid && phoneNumber.length > 2 && (
                  <p className="text-error">
                    Please enter a valid phone number.
                  </p>
                )}

                <label
                  htmlFor="paper-type"
                  className="block text-sm font-medium text-gray-700"
                >
                  Paper Type
                </label>
                <Select
                  inputId="paper-type"
                  options={paperOptions}
                  value={selectedPaperType}
                  onChange={setSelectedPaperType}
                  styles={customStyles}
                  className="w-[270px]"
                />

                <label
                  htmlFor="pages"
                  className="block text-sm font-medium text-gray-700"
                >
                  Number of Pages
                </label>
                <div className="flex items-center">
                  <button
                    type="button"
                    onClick={handlePageDecrement}
                    className="shadow-md bg-on-primary mr-[15px] p-2 text-gray-500 rounded"
                  >
                    <Icon path={mdiMinus} size={1} />
                  </button>
                  <span className="mx-2" id="pages">
                    {numberOfPages}
                  </span>
                  <button
                    type="button"
                    onClick={handlePageIncrement}
                    className="shadow-md bg-on-primary ml-[15px] p-2 text-gray-500 rounded"
                  >
                    <Icon path={mdiPlus} size={1} />
                  </button>
                </div>

                <label
                  htmlFor="due-date"
                  className="block text-sm font-medium text-gray-700"
                >
                  Due Date
                </label>
                <input
                  type="datetime-local"
                  id="due-date"
                  value={dueDate}
                  onChange={(e) => setDueDate(e.target.value)}
                  required
                  className="shadow border-0 focus:border-1 rounded w-[270px] py-2 px-3 text-secondary"
                />

                <label
                  htmlFor="password"
                  className="text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <span className="flex justify-end items-center">
                  <input
                    className="shadow border-0 focus:border-1 rounded w-[270px] py-2 px-3 text-secondary"
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Enter your password"
                    onChange={handlePasswordChange}
                    value={password}
                  />
                  <button
                    type="button"
                    className="absolute mr-4 hover:text-info"
                    onClick={handleShowPassword}
                  >
                    <Icon
                      path={showPassword ? mdiEyeOutline : mdiEyeOffOutline}
                      size={1}
                    />
                  </button>
                </span>
                {passwordError && <p className="text-error">{passwordError}</p>}

                <label
                  htmlFor="confirm-password"
                  className="text-sm font-medium text-gray-700"
                >
                  Confirm Password
                </label>
                <span className="flex justify-end items-center">
                  <input
                    className="shadow border-0 focus:border-1 rounded w-[270px] py-2 px-3 text-secondary"
                    id="confirm-password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Confirm your password"
                    onChange={handleConfirmPasswordChange}
                    value={confirmPassword}
                  />
                  <button
                    type="button"
                    className="absolute mr-4 hover:text-info"
                    onClick={handleShowPassword}
                  >
                    <Icon
                      path={showPassword ? mdiEyeOutline : mdiEyeOffOutline}
                      size={1}
                    />
                  </button>
                </span>
                {confirmPasswordError && (
                  <p className="text-error">{confirmPasswordError}</p>
                )}

                <button
                  type="submit"
                  disabled={
                    passwordError !== '' ||
                    confirmPasswordError !== '' ||
                    password === '' ||
                    confirmPassword === ''
                  }
                  className="mt-4 inline-flex items-end px-7 py-2 text-sm rounded-md shadow-sm text-black bg-primary hover:text-on-primary active:bg-tertiary-container"
                >
                  Submit
                </button>
              </div>

              {/* File Upload Section */}
              <div className="flex flex-col items-start gap-[15px] mt-[35px] ml-[35px]">
                <label
                  htmlFor="file-upload"
                  className="block text-sm font-medium text-gray-700"
                >
                  Upload Files
                </label>
                <FileUpload
                  onUpload={handleFileUpload}
                  editorRef={editorRef}
                  onError={handleFileUploadError}
                />
                {fileError && (
                  <div className="text-red-500 text-sm mt-2">{fileError}</div>
                )}
                <div className="text-sm flex flex-row">
                  <FileList
                    propFiles={uploadedFiles}
                    onRemove={handleRemoveFile}
                    onClearFiles={handleClearFiles}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>

        {/* Right Side Summary Panel */}
        <div className="shadow-xl bg-on-primary flex flex-col w-[350px] mr-[35px]">
          <div
            className="flex items-center justify-between px-[30px] gap-[35px] py-[15px] border-b-[2px] border-gray-300 cursor-pointer"
            onClick={toggleShowSummary}
          >
            <h3 className="text-xs">ORDER SUMMARY</h3>
            <motion.div animate={{ rotate: rotateSummary ? 180 : 0 }}>
              <FaChevronUp className="text-md" />
            </motion.div>
          </div>
          {showSummary && summary.length > 0 && (
            <motion.div
              className="p-[20px] text-xs bg-tertiary-container text-on-primary-container"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1 },
                collapsed: { opacity: 0 },
              }}
              transition={{ duration: 0.5, ease: 'easeIn' }}
            >
              <p>{summary}</p>
            </motion.div>
          )}

          <div
            className="flex items-center justify-between px-[30px] gap-[20px] py-[15px] border-b-[2px] border-gray-300 hover:cursor-pointer"
            onClick={toggleShowInstructions}
          >
            <h3 className="text-xs text-gray-600">Attachments and Files</h3>
            <motion.div animate={{ rotate: rotateInstructions ? 180 : 0 }}>
              <FaChevronUp className="text-sm" />
            </motion.div>
          </div>
          {showInstructions && uploadedFiles.length > 0 && (
            <motion.div
              className="p-[20px] text-xs bg-tertiary-container text-on-primary-container"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1 },
                collapsed: { opacity: 0 },
              }}
              transition={{ duration: 0.5, ease: 'easeIn' }}
            >
              <FileList
                propFiles={uploadedFiles}
                onRemove={handleRemoveFile}
                onClearFiles={handleClearFiles}
              />
            </motion.div>
          )}

          <div
            className="flex items-center justify-between px-[30px] gap-[20px] py-[15px] border-b-[2px] border-gray-300 hover:cursor-pointer"
            onClick={toggleShowMyDetails}
          >
            <h3 className="text-xs text-gray-600">My details</h3>
            <motion.div animate={{ rotate: rotateMyDetails ? 180 : 0 }}>
              <FaChevronUp className="text-sm" />
            </motion.div>
          </div>
          {showMyDetails && myDetails.length > 0 && (
            <motion.div
              className="p-[20px] text-xs bg-tertiary-container text-on-primary-container"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1 },
                collapsed: { opacity: 0 },
              }}
              transition={{ duration: 0.5, ease: 'easeIn' }}
            >
              {myDetails.map((detail, index) => (
                <div key={index} className="flex items-start gap-[10px]">
                  <FaRegCircle className="text-sm" />
                  <div>{detail}</div>
                </div>
              ))}
            </motion.div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CompleteRegistration
