import React, { ChangeEvent, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { addFile } from '../../slices/uploadSlice'
import { v4 as uuidv4 } from 'uuid'

const ALLOWED_FILE_EXTENSIONS = [
  '.jpg',
  '.jpeg',
  '.png',
  '.pdf',
  '.csv',
  '.doc',
  '.docx',
  '.ppt',
  '.xlsx',
]

interface FileUploadProps {
  onUpload: (files: File[]) => void
  editorRef: React.RefObject<any>
  onError: (errorMessage: string) => void
}

const FileUpload: React.FC<FileUploadProps> = ({
  onUpload,
  editorRef,
  onError,
}) => {
  const dispatch = useDispatch()

  const validateFileType = (fileName: string) => {
    const ext = fileName.substring(fileName.lastIndexOf('.')).toLowerCase()
    return ALLOWED_FILE_EXTENSIONS.includes(ext)
  }

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault()
      if (!event.target.files) return

      const filesArray = Array.from(event.target.files)
      const validFiles: File[] = []
      const errorMessages: string[] = []

      // Validate files
      for (const file of filesArray) {
        if (!validateFileType(file.name)) {
          errorMessages.push(`File "${file.name}" is not an allowed type.`)
        } else {
          validFiles.push(file)
        }
      }

      // If there are errors, notify the parent
      if (errorMessages.length > 0) {
        onError(errorMessages.join(' '))
      }

      // Handle only valid files
      if (validFiles.length > 0) {
        onUpload(validFiles)

        // Insert files into Quill and redux store
        validFiles.forEach((file) => {
          const fileData = {
            id: uuidv4(),
            file,
            name: file.name,
            url: URL.createObjectURL(file),
            mimeType: file.type,
            size: file.size,
          }

          dispatch(addFile(fileData))

          if (editorRef.current) {
            const quill = editorRef.current.getEditor()
            const cursorPosition = quill.getSelection()?.index || 0
            const fileLink = `<a href="${fileData.url}" download="${fileData.name}"> 📁 ${fileData.name}</a>`
            quill.clipboard.dangerouslyPasteHTML(cursorPosition, fileLink)
          }
        })
      }

      // Reset the input value so that the same file can be re-selected if needed
      event.target.value = ''
    },
    [dispatch, onUpload, editorRef, onError],
  )

  return (
    <div>
      <input
        type="file"
        multiple
        accept={ALLOWED_FILE_EXTENSIONS.join(', ')}
        onChange={handleFileChange}
        className="file-upload"
      />
    </div>
  )
}

export default FileUpload
