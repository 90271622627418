import React, { useState, useEffect } from 'react'
import { FaEnvelope, FaRegBell, FaSearch } from 'react-icons/fa'
import profile from '../../assets/download.png'
import { useUserContext } from '../../userContext'
import { gql, useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBell,
  faCircleXmark,
  faEnvelope,
  faPenToSquare,
} from '@fortawesome/free-solid-svg-icons'
import DndTesting from './ImprovedDND/DndTesting'
import Home from './Home'
import ProgressTab from './ProgressTab/dnd/ProgressTab'
import { Orders } from 'src/types'
import { useNavigate } from 'react-router-dom'
import CreateNewOrder from './CreateNewOrder'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface UserData {
  loggedInUser: {
    id: string
    firstName: string
    lastName: string
    userName: string
    email: string
    dateOfBirth: string
  }
}

const CURRENT_USER_QUERY = gql`
  query loggedInUser {
    loggedInUser {
      id
      firstName
      lastName
      userName
      email
      dateOfBirth
    }
  }
`

const DashboardHeader: React.FC = () => {
  const [open, setOpen] = useState(false)
  const [showOrderModal, setShowOrderModal] = useState(false)
  const { loginState, logoutState } = useUserContext()
  const navigate = useNavigate()

  const logoutUser = async () => {
    logoutState()
    window.location.href = '/'
  }

  const showDropDown = () => {
    setOpen(!open)
  }

  const { loading, data, error } = useQuery(CURRENT_USER_QUERY, {
    onCompleted: (data) => {
      const loggedInUser = data?.loggedInUser
      console.log('Logged In User:', loggedInUser)
    },
    onError: (error) => {
      console.error(error.message)
    },
  })
  if (loading) {
    console.log('Loading data')
  }

  const [user, setUser] = useState<UserData['loggedInUser'] | null>(null)

  useEffect(() => {
    if (loading) {
      console.log('Loading data')
    }

    if (data) {
      const { loggedInUser } = data
      setUser(loggedInUser)
    }
  }, [loading, data])
  return (
    <div>
      <div className="flex items-center justify-around h-[20vh] shadow-sm px-[25px]">
        <div className="flex items-center rounded-[5px]">
          <input
            type="text"
            className="bg-on-primary opacity-50 text-info h-[40px] outline-none pt-[13px] w-[350px] rounded-tl-[5px] rounded-bl-[5px] placeholder:text-[14px] leading-[20px] font-normal placeholder:text-info placeholder:pb-[33px] leading-20 px-4 py-3"
            placeholder="search for..."
          />
          <div className="bg-primary h-[40px] px-[14px] flex items-center justify-center cursor-pointer rounded-tr-[5px] rounded-br-[5px]">
            <FaSearch color="white" />
          </div>

          {/* Create new order */}
          <div
            className="bg-primary h-[40px] px-[14px] flex items-center justify-center cursor-pointer rounded-[5px] ml-3 relative group"
            onClick={() => {
              setShowOrderModal(true)
            }}
          >
            <FontAwesomeIcon
              icon={faPenToSquare as IconProp}
              className="text-on-primary"
            />
            <span className="absolute left-full top-1/2 transform -translate-y-1/2 ml-2 px-3 py-1 bg-secondary text-on-primary text-sm rounded whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity duration-200">
              Create New Order
            </span>
          </div>
        </div>
        <div className="flex items-center gap-[15px] relative">
          <div className="flex items-center gap-[25px] border-r-[1px] pr-[25px] text-info">
            <div className="relative cursor-pointer">
              <FontAwesomeIcon icon={faBell as IconProp} size="xl" />
              <span className="absolute -top-1 -right-2 text-xs text-on-primary bg-warning rounded-full px-[5px] py-[1px]">
                3
              </span>
            </div>
            <div className="relative cursor-pointer">
              <FontAwesomeIcon icon={faEnvelope as IconProp} size="xl" />
              <span className="absolute -top-1 -right-2 text-xs text-on-primary bg-warning rounded-full px-[5px] py-[1px]">
                3
              </span>
            </div>
          </div>
          <div
            className="flex items-center gap-[15px] relative"
            onClick={showDropDown}
          >
            <p className="text-info">
              Welcome,{' '}
              <span className="text-primary font-semibold text-[15px] cursor-pointer">
                {user?.firstName} {user?.lastName}
              </span>
            </p>
            <div className="h-[50px] w-[50px] rounded-full bg-primary cursor-pointer flex items-center justify-center relative ">
              <img src={profile} alt="profile" className="rounded-full" />
            </div>
            {open && (
              <div className="bg-on-primary h-[150px] w-[150px] absolute bottom-[-155px] z-20 right-0 pt-[15px] pl-[15px] space-y-[10px] border-primary border-[1px]">
                <p
                  className="cursor-pointer hover:text-primary font-semibold"
                  onClick={() => navigate('/')}
                >
                  Dashboard
                </p>
                <p className="cursor-pointer hover:text-primary font-semibold">
                  Profile
                </p>
                <p className="cursor-pointer hover:text-primary font-semibold">
                  Settings
                </p>
                <p
                  className="cursor-pointer hover:text-primary font-semibold"
                  onClick={logoutUser}
                >
                  Logout
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Create Order Modal */}
      {showOrderModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-secondary bg-opacity-50 z-30">
          <div className="bg-gradient-to-r to-primary from-secondary-container p-6 rounded-lg shadow-lg w-[90%] max-w-[1500px] max-h-[80vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Create New Order</h2>
              {/* Order form content goes here */}
              <button
                className="text-on-primary rounded hover:opacity-80"
                onClick={() => setShowOrderModal(false)}
              >
                <FontAwesomeIcon
                  icon={faCircleXmark as IconProp}
                  className="text-error text-2xl bg-on-primary rounded-full"
                />
              </button>
            </div>
            <div className="">
              <CreateNewOrder user={user} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DashboardHeader
