import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faBarsStaggered,
  faEnvelope,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import HowItWorks from '../HowItWorks/HowItWorks'
import Blog from '../Blog/Blog'
import LatestReviews from '../LatestReviews/LatestReviews'
import TopWriters from '../TopWriters/TopWriters'
import AboutUs from '../AboutUs/AboutUs'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from 'react-router-dom'
import './Navigation.css'
import LoginModal from '../LoginModal/LoginModal'
import UserContext from '../../App'
import httpClient from '../../config/httpClient'

// const cookies = new Cookies();
// const token = cookies.get("TOKEN");

const NavigationBar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const location = useLocation()
  const user = useContext(UserContext)

  const navigate = useNavigate()

  const logoutUser = async () => {
    await httpClient.post('//localhost:5000/logout')
    window.location.href = '/'
  }

  const menuItems = [
    { path: '/latestreviews', label: 'Latest reviews' },
    { path: '/topwriters', label: 'Top writers' },
    { path: '/samples', label: 'Samples' },
    { path: '/blog', label: 'Blog' },
  ]

  return (
    <div className="sticky top-0 z-40 w-[100vw]">
      <div className="flex justify-between lg:justify-around items-center bg-on-primary mt-[20px] mb-[5px]">
        <div className="">
          <img
            src="/media/logo.png"
            alt="logo image"
            className="w-[100px] ml-6 cursor-pointer"
            onClick={() => {
              navigate('/')
            }}
          />
        </div>
        <button
          className="pr-[35px] lg:hidden"
          onClick={() => {
            setIsSidebarOpen(true)
            document.body.style.overflow = 'hidden'
          }}
        >
          <FontAwesomeIcon
            icon={faBars}
            size="xl"
            className="text-on-primary-container "
          />
        </button>

        {/* Vissible on large screens       */}

        <ul className="hidden lg:flex justify-around items-center text-black font-bold lg:gap-[15px]">
          {location.pathname == '/latestreviews' ? (
            <li className="pr-3">
              <Link
                to="/latestreviews"
                className="bg-primary rounded-full py-2 px-3 font-semibold text-black text-md hover:text-on-primary"
              >
                Latest reviews
              </Link>
            </li>
          ) : (
            <li className="pr-3">
              <Link
                to="/latestreviews"
                className="font-semibold text-black text-md hover:text-primary"
              >
                Latest reviews
              </Link>
            </li>
          )}
          {location.pathname == '/topwriters' ? (
            <li className="pr-3">
              <Link
                to="/topwriters"
                className="bg-primary rounded-full py-2 px-3 text-md text-black font-bold hover:text-on-primary"
              >
                Top writers
              </Link>
            </li>
          ) : (
            <li className="pr-3">
              <Link
                to="/topwriters"
                className="text-md text-black font-bold hover:text-primary"
              >
                Top writers
              </Link>
            </li>
          )}
          {location.pathname == '/samples' ? (
            <li className="pr-3">
              <Link
                to="/samples"
                className="bg-primary rounded-full py-2 px-3 text-md text-black font-bold hover:text-on-primary"
              >
                Samples
              </Link>
            </li>
          ) : (
            <li className="pr-3">
              <Link
                to="/samples"
                className="text-md text-black font-bold hover:text-primary"
              >
                Samples
              </Link>
            </li>
          )}
          {location.pathname == '/blog' ? (
            <li className="">
              <Link
                to="/blog"
                className="bg-primary rounded-full py-2 px-3 text-md text-md text-black font-bold hover:text-on-primary"
              >
                Blog
              </Link>
            </li>
          ) : (
            <li className="">
              <Link
                to="/blog"
                className="text-md text-black font-bold hover:text-primary"
              >
                Blog
              </Link>
            </li>
          )}
        </ul>

        {/* Vissble on large screens */}
        <div className="hidden lg:flex items-center justify-around gap-[10px] mr-[35px] tracking-wider text-white font-semibold">
          <LoginModal />
        </div>
      </div>

      {/* Sidebar for small screens */}
      {isSidebarOpen ? (
        <div className="modal-bg lg:hidden z-10">
          <div className="absolute top-0 right-0 h-[100vh] w-[85vw] overflow-hidden bg-primary">
            <div className=" flex justify-end items-center h-[81px]">
              {/* <img src={logo} alt='logo image' /> */}
              <button
                onClick={() => {
                  setIsSidebarOpen(false)
                  document.body.style.overflow = 'auto'
                }}
                className="mr-[35px]"
              >
                <FontAwesomeIcon
                  icon={faBarsStaggered}
                  size="xl"
                  className="text-white"
                />
              </button>
            </div>
            <div className="bg-on-primary flex flex-col gap-[55px] text-white border-t-[2px] h-[100vh]">
              <div className="relative left-0 px-[10px] py-[10px]">
                <input
                  placeholder="search"
                  className="
        py-[10px]
        px-[10px]
        w-full
        border-gray-400
        border-[1px]
        rounded-[3px]
        text-gray-500
        focus:outline-none
        bg-gray-200"
                />
                <button className="absolute right-[10px] transform translate-y-1/2 -translate-x-1/2">
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    size="xl"
                    className="text-gray-400"
                  />
                </button>
              </div>

              {/* Reduce the gap between input and links */}
              <div className="flex flex-col justify-start items-center gap-[35px] tracking-wider">
                {/* Links in sidebar */}

                {/* <ul className='text-black font-bold lg:gap-[15px]'>
          {location.pathname == '/latestreviews' ? (
              <li className="p-[10px]">
                <Link
                  to="/latestreviews"
                  className="bg-primary rounded-full py-2 px-3 font-semibold text-black text-md hover:text-on-primary"
                >
                  Latest reviews
                </Link>
              </li>
            ) : (
              <li className="p-[10px]">
                <Link
                  to="/latestreviews"
                  className="font-semibold text-black text-md hover:text-primary"
                >
                  Latest reviews
                </Link>
              </li>
            )}
            {location.pathname == '/topwriters' ? (
              <li className="p-[10px]">
                <Link
                  to="/topwriters"
                  className="bg-primary rounded-full py-2 px-3 font-semibold text-black text-md hover:text-on-primary"
                >
                  Top writers
                </Link>
              </li>
            ) : (
              <li className="p-[10px]">
                <Link
                  to="/topwriters"
                  className="text-md text-black font-bold hover:text-primary"
                >
                  Top writers
                </Link>
              </li>
            )}
            {location.pathname == '/samples' ? (
              <li className="p-[10px]">
                <Link
                  to="/samples"
                  className="bg-primary rounded-full py-2 px-3 text-md text-black font-bold hover:text-on-primary"
                >
                  Samples
                </Link>
              </li>
            ) : (
              <li className="p-[10px]">
                <Link
                  to="/samples"
                  className="text-md text-black font-bold hover:text-primary"
                >
                  Samples
                </Link>
              </li>
            )}
            {location.pathname == '/blog' ? (
              <li className="p-[10px]">
                <Link
                  to="/blog"
                  className="bg-primary rounded-full py-2 px-3 text-md text-md text-black font-bold hover:text-on-primary"
                >
                  Blog
                </Link>
              </li>
            ) : (
              <li className="p-[10px]">
                <Link
                  to="/blog"
                  className="text-md text-black font-bold hover:text-primary"
                >
                  Blog
                </Link>
              </li>
            )}
        </ul> */}
                <ul className="text-black font-bold lg:gap-[15px]">
                  {menuItems.map(({ path, label }) => (
                    <li key={path} className="p-[10px]">
                      <Link
                        to={path}
                        className={`${
                          location.pathname === path
                            ? 'bg-primary rounded-full py-2 px-3 font-semibold text-md hover:text-on-primary'
                            : 'font-bold text-md hover:text-primary'
                        } text-black`}
                      >
                        {label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex justify-center tracking-wider">
                <div>
                  <LoginModal />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default NavigationBar
